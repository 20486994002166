import { Button, Image, Modal, Switch, Tag, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import { MainSubStructureModel, type PaginationData, RenderByPermission, request } from '../../../utils';
import EditorModel from './modal/editorModel';
import type { PlatformSysBusinessCardVO } from './type';

/**
 * 业务卡片管理模型
 */
export default class Model {
  constructor() {
    this.mainSubStructureModel?.gridModel?.onQuery();
  }

  /** 编辑模型 */
  public accessor editorModel = new EditorModel(this);

  /** 主从结构模型 */
  public accessor mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增招聘管家',
        permissionId: '2',
        handleClick: () => {
          this.editorModel.onOpen('add');
        },
      },
    ],
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 120,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    onClick={() => {
                      this.editorModel.onOpen('edit', row);
                    }}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <Button
                    onClick={() => {
                      this.onDeleteHandler(row.id);
                    }}
                    type="link"
                  >
                    删除
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'userInfo',
          name: '招聘管家信息',
          formatter: ({ row }) => {
            const { id, mobile, cardName } = row;
            return (
              <div style={{ height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>用户昵称：{cardName}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>ID：{id}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>手机号：{mobile}</div>
              </div>
            );
          },
        },
        {
          key: 'roleName',
          name: '角色',
          formatter: ({ row }) => {
            const { platformRoleVOList } = row;
            return platformRoleVOList?.length
              ? platformRoleVOList.map((v: { userRole: string; userRoleName: string }) => (
                  <Tag key={v.userRole}>{v.userRoleName}</Tag>
                ))
              : '';
          },
        },
        {
          key: 'businessCardName',
          name: '名片用途',
          formatter: ({ row }) => {
            const { type } = row;
            return type === 0 ? '服务主播' : '服务雇主';
          },
        },
        {
          key: 'canWork',
          name: '承接流量',
          formatter: ({ row }) => {
            return (
              <Switch
                checked={row.canWork === 1}
                onChange={() => {
                  this.onSwitchChange(row.id, row.canWork, 'canWork');
                }}
              />
            );
          },
        },
        {
          key: 'mainUrl',
          name: '形象照',
          width: 50,
          formatter: ({ row }) =>
            row.mainUrl && (
              <div className="flex items-center h-full">
                <Image
                  src={row.mainUrl}
                  style={{
                    width: '56px',
                    height: '56px',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ),
        },
        {
          key: 'cityList',
          name: '服务城市',
          formatter: ({ row }) => {
            const { cityList } = row;
            // biome-ignore lint/style/useBlockStatements: <explanation>
            if (!cityList?.length) return '';

            const tags = cityList.map((v: { id: number; name: string }) => <Tag key={v.id}>{v.name}</Tag>);

            const allTagsText = cityList.map((v: { id: number; name: string }) => v.name).join(', ');

            return (
              <Tooltip title={allTagsText}>
                <div>{tags}</div>
              </Tooltip>
            );
          },
        },
        {
          key: 'keyWord',
          name: '关键词',
          formatter: ({ row }) => {
            if (row.keyWord) {
              const keyWord = JSON.parse(row.keyWord);
              return keyWord?.map((v: string) => <Tag key={v}>{v}</Tag>);
            } else {
              return '';
            }
          },
        },
        {
          key: 'briefIntroduction',
          name: '简介',
          with: 300,
          formatter: ({ row }) => <Tooltip title={row.briefIntroduction}>{row.briefIntroduction}</Tooltip>,
        },
        {
          key: 'wechatCode',
          name: '企微二维码',
          width: 50,
          formatter: ({ row }) =>
            row.wechatCode && (
              <div className="flex items-center h-full">
                <Image
                  src={row.wechatCode}
                  style={{
                    width: '56px',
                    height: '56px',
                    objectFit: 'cover',
                  }}
                />
              </div>
            ),
        },
        {
          key: 'accountStatus',
          name: '状态',
          formatter: ({ row }) => {
            return (
              <Switch
                checked={row.accountStatus === 0}
                onChange={() => {
                  this.onSwitchChange(row.id, row.accountStatus, 'accountStatus');
                }}
              />
            );
          },
        },
        {
          key: 'latestUpdate',
          name: '更新人',
          formatter: ({ row }) => {
            const { latestUpdateByName, latestUpdateByMobile, latestUpdateBy } = row;
            return (
              <div style={{ height: '80px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>用户昵称：{latestUpdateByName}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>ID：{latestUpdateBy}</div>
                <div style={{ fontSize: '10px', lineHeight: '20px' }}>手机号：{latestUpdateByMobile}</div>
              </div>
            );
          },
        },
        {
          key: 'latestUpdateTime',
          name: '最后更新时间',
          sortable: true,
          formatter: ({ row }) => dayjs(row.latestUpdateTime).format('YYYY-MM-DD HH:mm:ss'),
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      rowHeight: 80,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      /**
       * 查询接口
       * @param {object} params - 请求参数
       * @returns {Promise<PaginationData<PlatformSysBusinessCardVO>>} 请求结果
       */
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        return request<PaginationData<PlatformSysBusinessCardVO>>({
          url: '/pbb/platform/broker/businessCard/list/page',
          method: 'POST',
          data: {
            type: 1,
            ...postParams,
          },
        });
      },
    },
  });

  // 删除标签分类
  private onDeleteHandler = (id: string) => {
    Modal.confirm({
      title: '提示',
      content: '是否确认删除该用户名片吗?',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({
          url: '/pbb/platform/broker/businessCard/delete',
          method: 'post',
          data: { id, type: 1 },
        });
        message.success('删除成功');
        this.mainSubStructureModel.onQuery();
      },
    });
  };

  private onSwitchChange = (id, value, type) => {
    console.log(value);
    const updateValue = value === 1 ? 0 : 1;
    console.log(updateValue);

    return request({
      url: '/pbb/platform/broker/businessCard/edit',
      method: 'post',
      data: {
        id,
        [type]: updateValue,
        type: 1,
      },
    }).then((resp) => {
      this.mainSubStructureModel.onQuery();
    });
  };
}
